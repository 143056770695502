import db from '@/db'
import firebase from '@/firebase'

import {
    PUSH_CHECKIN,
    RESET_CHECKINS,
    SET_CHECKIN_THOUGHT_LOADING
    } from './mutations'
  
  const state = {
    checkins: [],
    //thoughts: [],   //DEPRICATED
    loading: false
  }
  
  const getters = {
    checkins: ({checkins}) => checkins,
    //thoughts: ({thoughts}) => thoughts,  //DEPRICATED
    loading: ({loading}) => loading
  }
  
  const mutations = {
    [SET_CHECKIN_THOUGHT_LOADING] (state, bool) {
      state.loading = bool
    },
    [PUSH_CHECKIN] (state, checkin) {
      state.checkins.push(checkin)
    },
    [RESET_CHECKINS] (state) {
        state.checkins = []
    },
    /* [PUSH_THOUGHT] (state, thought) {   //DEPRICATED
      state.thoughts.push(thought)
    },
    [RESET_THOUGHTS] (state) {    //DEPRICATED
        state.thoughts = []
    } */
  }
  
  const actions = {
    addCheckin({commit}, payload) {
        commit(PUSH_CHECKIN, payload)
    },
    resetCheckins({commit}) {
        commit(RESET_CHECKINS)
    },
    async getCheckins({commit}) {
      firebase.auth().onAuthStateChanged(async (user) => {  
      //const user = await firebase.auth().currentUser
        if(user) {
          //console.log("user.uid for getting checkins: "+user.uid)
          await db.collection('checkins').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').onSnapshot(snap => {
            //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_CHECKINS)
            snap.forEach((doc) => {
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              //console.log("pushing a checkin: "+ JSON.stringify(doc.data()))
              commit(PUSH_CHECKIN, {
                  id: doc.id,
                  timeCreated: timestamp,
                  dateCreated: dateCreated,
                  ...doc.data()
              })
            })
          })
        }
      })
    },
    /* async getThoughts({commit}) {        //DEPRECATED
      commit(SET_CHECKIN_THOUGHT_LOADING, true)
      firebase.auth().onAuthStateChanged(async (user) => { 
      
        if(user) {
          //const user = firebase.auth().currentUser
          //console.log("got to getThoughts")
          //console.log("user uid: "+user.uid)
          var i = 0
          await db.collection('statements')
          .where("statementUserUid", "==", user.uid)
          .where("statementType", "==", 'feeling_thought')
          .orderBy('dateCreated', 'desc')
          .limit(10)
          .onSnapshot(snap => {
          //.get().then(snap => {   //can also use onSnapshot
            //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_THOUGHTS)
            snap.forEach((doc) => {
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              console.log("pushing a thought: "+ JSON.stringify(doc.data()))
              commit(PUSH_THOUGHT, {
                  id: doc.id,
                  timeCreated: timestamp,
                  dateCreated: dateCreated,
                  ...doc.data()
              })
            })
            i++
            if(i > 2) {
              commit(SET_CHECKIN_THOUGHT_LOADING, false)
            }
          })
        }
      })
    }, */
   async submitCheckIn({rootState, dispatch}, payload) {
      //var sRef = await db.collection('statements').doc()
      var checkinRef = await db.collection('checkins').doc()
      var userCheckinRef = await db.collection('users').doc(rootState.auth.profile.uid).collection('checkInLog').doc()
      var newDate = new Date();
      await checkinRef.set({
        'feeling': payload.feelingObj,
        'thought': payload.thoughtObj,
        'userUid': rootState.auth.profile.uid,
        'dateCreated': newDate
      })

      var statementId
      if(payload.thoughtObj.thoughtText !== '') {
        statementId = await dispatch('statement/createStatement', {
          'dateCreated': newDate,
          'dateUpdated': newDate,
          'minderId': null,
          'momentId': null,
          'statementFeeling': payload.feelingObj,  //exclusive property to feelingThought statements
          'statementOriginalText': payload.thoughtObj.thoughtText,
          'statementStimulusText': payload.thoughtObj.thoughtStimulusQuestion,
          'statementType': 'feelingThought',
        } , {root: true})
      }


      await userCheckinRef.set({
        'feeling': payload.feelingObj,
        'thought': payload.thoughtObj,
        'dateCreated': newDate
      })

      //may not need to update these with statementIds
      if(payload.thoughtObj.thoughtText !== '') {
        await db.collection('checkins').doc(checkinRef.id).set({
          'statementId': statementId
        }, {merge:true})

        await db.collection('users').doc(rootState.auth.profile.uid).collection('checkInLog').doc(userCheckinRef.id).set({
          'statementId': statementId
        }, {merge:true})

      }

      //await dispatch('feeling/saveFeelingToUser', {'userUid': rootState.auth.profile.uid, 'feelingObj':payload.feelingObj}, {root: true})
      //await dispatch('checkin/saveFeelingToUser', {'userUid': rootState.auth.profile.uid, 'thoughtObj':payload.thoughtObj}, {root: true})
      




    },

  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }