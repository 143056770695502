import db from '@/db'
import firebase from '@/firebase'

import {
    SET_FEELING,
    RESET_FEELING,
    PUSH_USER_FEELING,
    RESET_USER_FEELINGS
  } from './mutations'
  
  const state = {
    userFeelings: [],
    newFeeling: {
        feelingText: '',
        feelingValue: '',
        feelingType: '',
        feelingMeasure: '',

    }
    
  }
  
  const getters = {
    userFeelings: ({userFeelings}) => userFeelings,
    newFeeling: ({newFeeling}) => newFeeling
    
  }
  
  const mutations = {
    [PUSH_USER_FEELING] (state, feeling) {
      state.userFeelings.push(feeling)
    },
    [RESET_USER_FEELINGS] (state) {
      state.userFeelings = []
    },
    [SET_FEELING] (state, payload) {
      state.newFeeling.feelingText = payload.feelingText,
      state.newFeeling.feelingValue = payload.feelingValue,
      state.newFeeling.feelingType = payload.feelingType,
      state.newFeeling.feelingMeasure = payload.feelingMeasure
    },
    [RESET_FEELING] (state) {
        state.newFeeling.feelingText = '',
        state.newFeeling.feelingValue = '',
        state.newFeeling.feelingType = '',
        state.newFeeling.feelingMeasure = ''
    }
  }
  
  const actions = {
    setFeeling({commit}, payload) {
        commit(SET_FEELING, payload)
    },
    resetFeeling({commit}) {
        commit(RESET_FEELING)
    },
    async saveFeelingMeasurementToUser ({state, commit}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          commit(SET_FEELING, payload.feelingObj)
          var ref = db.collection('feelings').doc()
          await ref.set({
              ...state.newFeeling,
              'userUid': payload.userUid,
              'feelingMeanCategory': null,
              'dateCreated': new Date()
          })

          //add feeling to user's feelingLog
          var ref2 = db.collection('users').doc(user.uid).collection("feelingMeasurements").doc()
          
          await ref2.set({
            ...state.newFeeling,
              'userUid': payload.userUid,
              'feelingMeanCategory': null,
              'dateCreated': new Date()
          })
        
        } else {
          alert('User not logged in; feeling reading was not created')
        }
      })
    },
    async getFeelings({commit}) {
      firebase.auth().onAuthStateChanged(async (user) => {  
      //const user = await firebase.auth().currentUser
        if(user) {
          //console.log("user.uid for getting feelings: "+user.uid)
          await db.collection('feelings').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').onSnapshot(snap => {
            //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_USER_FEELINGS)
            snap.forEach((doc) => {
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              //console.log("pushing a feeling: "+ JSON.stringify(doc.data()))
              commit(PUSH_USER_FEELING, {
                  'id': doc.id,
                  'timeCreated': timestamp,
                  'dateCreated': dateCreated,
                  ...doc.data()
              })
            })
          })
        }
      })
    },

    async deleteFeelingInFB (_, payload) {
        const user = firebase.auth().currentUser
        if (user) {
            db.collection('feelings').doc(payload.feelingId).delete()
        } else {
            alert("You are not logged in; feeling was not deleted")
        }
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }