<template>
<form ref="form">
  <div class="wrapper">
    <div class="card">
      <div class="stimulusQuestion">
        <div v-if="this.thoughtType === 'actionAfterAction'">
          <h1>{{ this.cmsText.thoughtStimulusQuestion + this.actionMeanCategory }}</h1>
        </div>
        <div v-else>
          <h1>{{ this.cmsText.thoughtStimulusQuestion }}</h1>
        </div>
      </div>
      <div class="textField">
        <v-text-field
          ref="thought_input"
          name="thought"
          :id="'unique'"
          :rules="rules"
          counter="100"
          clearable
          type="text"
          v-model="thought"
          @mouseover="giveFocus">
        </v-text-field>
      </div>  
    </div>
    <div class="buttonHolder">
      <v-btn to="/" flat>Cancel</v-btn>
      <v-btn @click="onButtonClick">{{ this.buttonText }}</v-btn>
    </div>
    
  </div>
</form>
</template>
<script>
    //import Worker from "worker-loader!../worker";
    import '@/plugins/vuetify'
    import thoughtModule from '@/store/thought'
    import { mapActions } from 'vuex'
    

  export default {
    name: "ThoughtIntake",
    props: {
      buttonText: {
        type: String
      },
      cmsText: {
        type: Object
      },
      thoughtType: {
        type: String
      },
      parentView: {
        type: String
      },
      actionMeanCategory: {
        type: String
      }
    },
    components: {
      
    },
    data () {
      return {
        thought: '',
        randomNumber: null,
        rules: [
          //(v) => !!v || 'Statement is required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],
        
      }
    },
    computed: {
      
    },
    methods: {
      ...mapActions('thought', [
        'setThought'
      ]),
      onButtonClick () {
        //create a thought obj
        var obj = {
          'thoughtStimulusQuestion': this.cmsText.thoughtStimulusQuestion,
          'thoughtText': this.thought
        }
        this.setThought(obj)  //set vuex thought obj
        this.$emit('continue1', obj)   //emit thought obj out to parent controller
      },
      giveFocus () {
        this.$refs.thought_input.focus()
      },
      generateRandomNumber () {
        return Math.floor((Math.random() * 1000) + 1);
      },
      cancel () {
        this.$emit('cancel')
      }
      

    },
    beforeCreate () {
      if(!this.$store.state.thought) this.$store.registerModule('thought', thoughtModule)
    },
    created () {
    
    },
    mounted () {
      
    },
    updated () {
     
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

.card {
  background-color: white;
  padding: 25px;
}

.buttonHolder {
  text-align: right;
  padding: 4px;
}

.textField {
  margin-top: 20px;
  margin-bottom: 30px;
}


</style>