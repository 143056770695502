import db from '@/db'
import firebase from '@/firebase'

import {
    SET_THOUGHT,
    RESET_THOUGHT,
    SET_CHECKIN_THOUGHT_LOADING,
    PUSH_THOUGHT,
    RESET_THOUGHTS
  } from './mutations'
  
  const state = {
    newThought: {
        thoughtText: '',
        thoughtStimulusQuestion: ''
    },
    thoughts: [],
    loading: false,
    
  }
  
  const getters = {
    newThought: ({newThought}) => newThought,
    thoughts: ({thoughts}) => thoughts,
    loading: ({loading}) => loading
    
  }
  
  const mutations = {
    [SET_THOUGHT] (state, payload) {
      state.newThought.thoughtText = payload.thoughtText
      state.newThought.thoughtStimulusQuestion = payload.thoughtStimulusQuestion
    },
    [RESET_THOUGHT] (state) {
      state.newThought.thoughtText = ''
      state.newThought.thoughtStimulusQuestion = ''
    },
    [SET_CHECKIN_THOUGHT_LOADING] (state, bool) {
      state.loading = bool
    },
    [PUSH_THOUGHT] (state, thought) {
      state.thoughts.push(thought)
    },
    [RESET_THOUGHTS] (state) {
        state.thoughts = []
    }
  }
  
  const actions = {
    setThought({commit}, payload) {
        commit(SET_THOUGHT, payload)
    },
    resetThought({commit}) {
        commit(RESET_THOUGHT)
    },
    /* async createThoughtInFB ({state}, payload) {
        const user = firebase.auth().currentUser
        if (user) {
            var ref = db.collection('minders').doc()
            await ref.set({
                ...state.newThought,
                userUid: payload.userUid,
                thoughtText: this.newThought.thoughtText,
                thoughtMeanCategory: payload.meanCategory,
                thoughtDateCreated: new Date()
            })
        
        } else {
            alert('User not logged in; thought was not created')
        }
    }, */

    async deleteThoughtInFB (_, payload) {
        const user = firebase.auth().currentUser
        if (user) {
            db.collection('thoughts').doc(payload.thoughtId).delete()
        } else {
            alert("You are not logged in; thought was not deleted")
        }
    },

    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }