<template>
<div class="wrapper">
  <v-card class="mx-auto mb-5" flat>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <span v-if="feelingType=='Happiness'" class="subheading">{{ this.cmsText.feelingHappinessText }}</span>
        <span v-if="feelingType=='Confidence'" class="subheading">{{ this.cmsText.feelingConfidenceText }}</span>
        <span v-if="feelingType=='Motivation'" class="subheading">{{ this.cmsText.feelingMotivationText }}</span>
      </v-toolbar-title>
      
    </v-toolbar>

    <v-card-text>
      
        
          <span
            class="display-3 font-weight-light"
            v-text="sliderValue"
          ></span>
          <div v-if="feelingType=='Happiness'">
            <span class="subheading font-weight-light mr-1">{{ this.cmsText.feelingHappinessMeasure }}</span>
            <img v-if="showEmoji95" :src="require('@/assets/emoji-95_80x76.png')" alt="face image">
            <img v-if="showEmoji80" :src="require('@/assets/emoji-80_80x76.png')" alt="face image">
            <img v-if="showEmoji65" :src="require('@/assets/emoji-65_80x76.png')" alt="face image">
            <img v-if="showEmoji50" :src="require('@/assets/emoji-50_80x76.png')" alt="face image">
          </div>
          <div v-else-if="feelingType=='Confidence'">
            <span class="subheading font-weight-light mr-1">{{ this.cmsText.feelingConfidenceMeasure }}</span>
          </div>
          <div v-else-if="feelingType=='Motivation'">
            <span class="subheading font-weight-light mr-1">{{ this.cmsText.feelingMotivationMeasure }}</span>
          </div>
      <v-slider
        v-model="sliderValue"
        :color="color"
        track-color="grey"
        always-dirty
        min="0"
        max="100"
      >
        <template v-slot:prepend>
          <i @click="decrement" class="fas fa-minus"></i>&nbsp;&nbsp;&nbsp;
        </template>

        <template v-slot:append>
          &nbsp;&nbsp;&nbsp;<i @click="increment" class="fas fa-plus"></i>
        </template>
      </v-slider>
    </v-card-text>
  </v-card>
  <div class="buttonWrapper">

    
      
      <v-btn flat to="/">Cancel</v-btn>
      <v-btn color="primary" @click="ratingPassed">{{this.buttonText}}</v-btn>


  </div>
</div>
</template>

<script>
  //import Worker from "worker-loader!../worker";
  import '@/plugins/vuetify'
  //import { VSlider, VBtn } from 'vuetify/lib'
  import feelingModule from '@/store/feeling'
  import { mapActions } from 'vuex'
  

  export default {
    name: "FeelingIntake",
    components: {
      //VSlider
    },
    props: {
      buttonText: {
        type: String,
      },
      cmsText: {
        type: Object,

      },
      mode: {
        type: String,
      }
    },

    
    data: () => ({
      sliderValue: 50,
      interval: null,
      isPlaying: false,
      feelingType: null,
      color: "red",

    }),
    computed: {
      showEmoji95 () {
       return ((this.sliderValue <= 100) && (this.sliderValue >= 95)) ? true : false
      },
      showEmoji80 () {
       return ((this.sliderValue < 95) && (this.sliderValue >= 80)) ? true : false
      },
      showEmoji65 () {
       return ((this.sliderValue < 80) && (this.sliderValue >= 65)) ? true : false
      },
      showEmoji50 () {
       return ((this.sliderValue < 65) && (this.sliderValue >= 50)) ? true : false
      },
      animationDuration () {
        return `${60 / this.sliderValue}s`
      },
    },
    methods: {
      decrement () {
        this.sliderValue--
      },
      increment () {
        this.sliderValue++
      },
      toggle () {
        this.isPlaying = !this.isPlaying
      },
      ...mapActions('feeling', [
        'setFeeling'
      ]),
      ratingPassed () {
        var measure
        var text
        if (this.feelingType == 'Happiness') {
          measure = this.cmsText.feelingHappinessMeasure
          text = this.cmsText.feelingHappinessText
        } else if (this.feelingType == 'Confidence') {
          measure = this.cmsText.feelingConfidenceMeasure
          text = this.cmsText.feelingConfidenceText
        } else if (this.feelingType == 'Motivation') {
          measure = this.cmsText.feelingMotivationMeasure
          text = this.cmsText.feelingMotivationText
        }

        var feelingObj = {
          'feelingValue': this.sliderValue,
          'feelingType': this.feelingType,
          'feelingMeasure': measure,
          'feelingText': text
        }
        this.setFeeling(feelingObj)
        this.$emit('continue2', feelingObj)
      },
      determineWhichFeelingToAskAbout () {
        if(this.mode === 'happyOnly') {
          this.feelingType = "Happiness"
          return
        }
        //randomly pick a number between 1 - 3
        var min = 1
        var max = 3
        var num = Math.floor(Math.random() * (max - min + 1) + min)
        //switch / case and set a variable
        switch(num) {
          case 1:
            this.feelingType = "Motivation"
            break
          case 2:
            this.feelingType = "Confidence"
            break
          case 3:
            this.feelingType = "Happiness"
            break
        }
      }
      

    },
    beforeCreate () {
      if(!this.$store.state.feeling) this.$store.registerModule('feeling', feelingModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();

      this.determineWhichFeelingToAskAbout();
    
    },
    mounted () {
      //this.$refs.searchBox.focus()
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.buttonWrapper {
  text-align: right;
}
@keyframes metronome-example {
    from {
      transform: scale(.5);
    }

    to {
      transform: scale(1);
    }
  }

  .v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

</style>